import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import AuthenticationPage, { action as authAction} from './pages/Authentication';
import PrevClientsPage from './pages/PrevClients';
import RootLayout from './pages/Root';
import AddPostPage from './pages/AddPost';
import HomePage from './pages/Home';
import { tokenLoader, checkAuthLoader } from './utils/auth';
import { action as logoutAction } from './pages/Logout'
import MessagesPage, { loader as messagesLoader} from './pages/Messages';

const messagesPageLoader = async () => {
  return Promise.all([checkAuthLoader(), messagesLoader()])
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    id: 'root',
    loader: tokenLoader,
    children: [
      { index: true, element: <HomePage /> },
      { path: 'auth', element: <AuthenticationPage />, action: authAction },
      { path: 'clients', loader: checkAuthLoader, element: <PrevClientsPage />},
      { path: 'addPost', loader: checkAuthLoader, element: <AddPostPage /> },
      { 
        path: 'messages', 
        loader: messagesPageLoader, 
        element: <MessagesPage />
      },
      { path: 'logout', action: logoutAction }
    ]
  }
])

function App() {
  return <RouterProvider router={router} />
}

export default App;

export const BASE_URL = "https://tusharpandya-backend.onrender.com"
