import { useLoaderData } from "react-router-dom"
import Container from "react-bootstrap/Container"
import MessageItem from "../components/MessageItem"
import { BASE_URL } from "../App"

const MessagesPage = () => {
    const [, messagesLoaderData] = useLoaderData()

    return (
        <Container style={{ marginTop: '8rem' }}>
            {messagesLoaderData.map((msg, index) => {
                return <MessageItem key={index} messageData={msg}/>
            })}
        </Container>
    )
}

export default MessagesPage

export const loader = async () => {

    const response = await fetch(BASE_URL+'/messages', {
        method: 'GET'
    })

    if (!response.ok) {
        throw response
    }

    const resData = await response.json()
    return resData.visitorMessages
}