import Card from "react-bootstrap/Card"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const MessageItem = (props) => {
    const { name, phone, email, message} = props.messageData

    return (
        <Card className="mb-3">
            <Card.Body>
                <Container>
                    <Row>
                        <Col>
                            <p>Name: {name}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Phone: {phone}</p>
                        </Col>
                    </Row>
                    {email !== '' && email !== undefined && (
                        <Row>
                            <Col>
                                <p>Email: {email}</p>
                            </Col>
                        </Row>
                    )}
                    {message !== '' && message !== undefined && (
                        <Row>
                            <Col>
                                <p>Message: {message}</p>
                            </Col>
                        </Row>
                    )}
                </Container>
            </Card.Body>
        </Card>
    )
}

export default MessageItem