import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from "react-bootstrap/Button";

import { useRouteLoaderData, useSubmit, Link } from 'react-router-dom'

const Navigation = () => {
    const token = useRouteLoaderData('root')
    const submit = useSubmit()

    const logoutHandler = () => {
        submit(null, { method: 'post', action: '/logout' })
    }

    return (
        <Navbar expand="lg" className="bg-body-tertiary fixed-top">
            <Container>
                <Navbar.Brand style={{ fontWeight: '800', fontSize: '1.5rem' }}>TUSHAR PANDYA</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
                    {token && (
                        <Nav className="me-2">
                            <Nav.Link as={Link} to='clients'>Add Client</Nav.Link>
                            <Nav.Link as={Link} to="addPost">Add Post</Nav.Link>
                            <Nav.Link as={Link} to="messages">Visitor Messages</Nav.Link>
                            <Button variant="dark" onClick={logoutHandler} className='ms-5'>Logout</Button>
                        </Nav>
                    )}
                    {!token && (
                        <Link to="/auth?mode=login">
                            <Button variant="dark">Login</Button>
                        </Link>
                    )}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Navigation