import { redirect } from "react-router-dom"
import AddPostForm from "../components/AddPostForm"
import { BASE_URL } from "../App"

const AddPostPage = () => {
    const submitPostDataHandler = async (postData) => {
        const response = await fetch(BASE_URL+'/posts', {
            method: 'POST',
            body: postData
        })

        if (!response.ok) {
            throw response
        }

        return redirect('/addPost')
    }

    return <AddPostForm submitPostData={submitPostDataHandler} />
}

export default AddPostPage