import { redirect } from "react-router-dom"
import AddClientForm from "../components/AddClientForm"
import { BASE_URL } from "../App"

const PrevClientsPage = () => {
    const submitClientDataHandler = async (clientData) => {
        const response = await fetch(BASE_URL+'/prevClients', {
            method: 'POST',
            body: clientData
        })

        if (!response.ok) {
            throw response
        }

        return redirect('/addPost')
    }

    return <AddClientForm submitClientData={submitClientDataHandler} />
}

export default PrevClientsPage

// export async function action({ request }) {
//     const data = await request.formData()

//     const logo = await data.get('logo')

//     console.log(logo.size)

//     const clientData = {
//         companyName: data.get('name'),
//         logo: await data.get('logo')
//     }

//     const response = await fetch('http://localhost:8080/prevClients', {
//         method: 'POST',
//         body: clientData
//     })

//     if(!response.ok) {
//         throw response
//     }

//     return redirect('/addPost')

// }