import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { useRef } from 'react';

const AddClientForm = (props) => {
    const companyNameInputRef = useRef()
    const companyLogoInputRef = useRef()

    const submitFormHandler = (e) => {
        e.preventDefault()

        const formdata = new FormData()
        formdata.append('logo', companyLogoInputRef.current.files[0])
        formdata.append('companyName', companyNameInputRef.current.value)

        props.submitClientData(formdata)
    }

    return (
        <Container className='p-5 w-50 shadow' style={{ marginTop: '8rem'}}>
            <Row>
                <Col>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Company Name" ref={companyNameInputRef} autoComplete='off'/>
                        </Form.Group>

                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Company Logo Image</Form.Label>
                            <Form.Control type="file" ref={companyLogoInputRef}/>
                            <Form.Text className="text-muted">
                                File type must be jpg/jpeg/png and file size must be less then 1mb.
                            </Form.Text>
                        </Form.Group>

                        <Button variant="primary" type="submit" onClick={submitFormHandler}>
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default AddClientForm