import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const HomePage = () => {
    return (
        <Container style={{ paddingTop: '200px', fontWeight: '900', fontSize: '4rem'}}>
            <Row>
                <Col>
                    <p>Welcom to admin panel of tusharpandya.com</p>
                </Col>
            </Row>
        </Container>
    )
}

export default HomePage