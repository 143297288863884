import { useState } from "react"

const useInput = (validateValue) => {
    const [value, setValue] = useState('')
    const [isTouched, setIsTouched] = useState(false)

    const valueIsValid = validateValue(value)
    const hasError = !valueIsValid && isTouched

    const inputBlurHandler = () => {
        setIsTouched(true)
    }

    const inputChangeHandler = (event) => {
        setValue(event.target.value)
    }

    const reset = () => {
        setValue('')
        setIsTouched(false)
    }

    return {
        value,
        valueIsValid,
        hasError,
        inputChangeHandler,
        inputBlurHandler,
        reset
    }
}

export default useInput