import { Link, useSearchParams, useSubmit } from 'react-router-dom';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import useInput from '../hooks/use-input'

const AuthForm = () => {
    const [searchParams] = useSearchParams()
    const isLogin = searchParams.get('mode') === 'login'

    const emailValidation = (value) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return value.trim() !== '' && emailRegex.test(value)

    }

    const {
        value: emailInput,
        valueIsValid: emailInputIsValid,
        hasError: emailInputHasError,
        inputChangeHandler: emailInputChangeHandler,
        inputBlurHandler: emailInputBlurHandler,
        reset: emailInputReset
    } = useInput(emailValidation)

    const {
        value: passwordInput,
        valueIsValid: passwordInputIsValid,
        hasError: passwordInputHasError,
        inputChangeHandler: passwordInputChangeHandler,
        inputBlurHandler: passwordInputBlurHandler,
        reset: passwordInputReset
    } = useInput(value => value.trim() !== '' && value.trim().length > 7)

    let formIsValid = false
    if (emailInputIsValid && passwordInputIsValid) {
        formIsValid = true
    }

    const submit = useSubmit()
    const submitFormHandler = (event) => {
        event.preventDefault()

        if (!passwordInputIsValid || !emailInputIsValid) {
            return
        }
        const data = {
            email: emailInput,
            password: passwordInput
        }
        submit(data, { method: 'post' })

        emailInputReset()
        passwordInputReset()
    }

    return (
        <Container className='p-5 w-50 shadow' style={{ marginTop: '8rem'}}>
            <Row>
                <Col>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={emailInput}
                                onChange={emailInputChangeHandler}
                                onBlur={emailInputBlurHandler}
                                autoComplete='off'
                                isInvalid={emailInputHasError}
                                required
                            />
                            {emailInputHasError && (
                                <Form.Control.Feedback type="invalid">Please enter a value.</Form.Control.Feedback>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                value={passwordInput}
                                onChange={passwordInputChangeHandler}
                                onBlur={passwordInputBlurHandler}
                                isInvalid={passwordInputHasError}
                                required
                            />
                            {passwordInputHasError && (
                                <Form.Control.Feedback type="invalid">Please enter a value.</Form.Control.Feedback>
                            )}
                        </Form.Group>

                        <Button variant="dark" type="submit" className='me-3' onClick={submitFormHandler} disabled={!formIsValid}>
                            {isLogin ? 'Login' : 'Sign Up'}
                        </Button>

                        <Link to={`?mode=${isLogin ? 'signup' : 'login'}`}>
                            {isLogin ? 'Don\'t have an account?' : 'Already have an account?'}
                        </Link>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default AuthForm