import { redirect } from "react-router-dom"
import { getAuthToken } from "../utils/auth"
import { BASE_URL } from "../App"

export async function action() {
    const token = getAuthToken()

    const response = await fetch(BASE_URL+'/admin/logout', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

    if(!response.ok) {
        throw response
    }

    localStorage.removeItem('token')


    return redirect('/')
}