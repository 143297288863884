import { redirect, json } from "react-router-dom"
import AuthForm from "../components/AuthForm"
import { BASE_URL } from "../App"

const AuthenticationPage = () => {
    return <AuthForm />
}

export default AuthenticationPage

export async function action({ request }) {
    const searchParams = new URL(request.url).searchParams
    const mode = searchParams.get('mode') || 'login'

    if(mode !== 'login' && mode !== 'signup') {
        throw json({message: 'Unsupported node'}, { status: 422 })
    }

    const data = await request.formData()

    const authData = {
        email: data.get('email'),
        password: data.get('password')
    }

    const response = await fetch(BASE_URL+'/admin/' + mode, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(authData)
    })

    if(!response.ok) {
        throw response
    }

    const resData = await response.json()
    const token = resData.token

    localStorage.setItem('token', token)

    return redirect('/clients')
}