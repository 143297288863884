import { useRef } from 'react';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';

const AddPostForm = (props) => {
    const titleInputRef = useRef()
    const imageInputRef = useRef()
    const descriptionInputRef = useRef()

    const submitFormHandler = (e) => {
        e.preventDefault()

        const formdata = new FormData()
        formdata.append('image', imageInputRef.current.files[0])
        formdata.append('title', titleInputRef.current.value)
        formdata.append('description', descriptionInputRef.current.value)

        props.submitPostData(formdata)
    }

    return (
        <Container className='p-5 w-50 shadow' style={{ marginTop: '8rem' }}>
            <Row>
                <Col>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicText">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter title for the Post"
                                autoComplete='off'
                                ref={titleInputRef}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Image</Form.Label>
                            <Form.Control type="file" ref={imageInputRef} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={6} ref={descriptionInputRef} required/>
                        </Form.Group>

                        <Button variant="primary" type="submit" onClick={submitFormHandler}>
                            Submit
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default AddPostForm